import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment/moment";

const slice = createSlice({
  name: "telegram",
  initialState: {
    telegramSpinReward: [],
    telegramUsdtWithdrawals: [],
    telegramUsers: [],
    telegramSetting: [],
    telegramUserTransactions: [],
    telegramChallenges: [],
    telegramChallengesTotalCount: 0,
    verisoulMultipleTelegramAccounts: [],
    verisoulMultipleTelegramAccountsById: [],
    verisoulTelegramAuthResults: [],
    verisoulAuthResultsById: [],
    loading: false,
    loadingChallenges: false,
    verisoulMultipleTelegramAccountsByIdLoading: false,
    verisoulTelegramAuthResultsLoading: false,
    verisoulMultipleTelegramAccountsLoading: false,
    totalCount: 0,
    lastFetch: null,
    totalChallengesCount: 0,
    verisoulMultTelegramAccFilteredCount: 0,
    verisoulMultTelegramAccTotalCount: 0,
    telegramUserTransactionsTotalCount: 0,
    prizes: [],
    sweepstakes: [],
    sweepstakePagination: null,
    sweepstakesTemplate: [],
    verisoulTelegramAuthTotalCount: 0,
    verisoulAuthResultsByIdTotalCount: 0,
    verisoulTelegramAuthFilteredCount: 0,
    participants: [],
    participantsPagination: {},
    winners: [],
    teamPlayChallenges: [],
    dailyTournamentLeaderboard: [],
    teamPlayTotalCount: 0,
    quests: [],
    questsLoading: false,
    dailyTournament: [],
    dailyTournamentLoading: false,
    loadingMore: false,
    dailyTournamentLeaderboardLoading: false,
    telegramGames: [],
    telegramGamesTotalCount: 0,
    telegramGamesLoading: false,
    teammates: [],
    loadingTeammates: false,
    teammatesTotalCount: 0,
    teammatesFilteredCount: 0,
  },
  reducers: {
    telegramSpinRewardRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramSpinRewardReceived: (misc, action) => {
      misc.telegramSpinReward = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramSpinRewardRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsdtWithdrawalsRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUsdtWithdrawalsReceived: (misc, action) => {
      misc.telegramUsdtWithdrawals = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUsdtWithdrawalsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsdtStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.telegramUsdtWithdrawals = misc.telegramUsdtWithdrawals?.map((request, index) => {
        const newRequest = { ...request };
        if (payLoad?.id === newRequest?._id) {
          newRequest.status = 2;
        }
        return newRequest;
      });
    },
    telegramSettingRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramSettingReceived: (misc, action) => {
      misc.telegramSetting = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramSettingRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsersRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUsersReceived: (misc, action) => {
      misc.telegramUsers = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUsersRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUserTransactionsRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUserTransactionsReceived: (misc, action) => {
      misc.telegramUserTransactions = action.payload.data;
      misc.telegramUserTransactionsTotalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreTelegramUserTransactionsReceived: (misc, action) => {
      misc.telegramUserTransactions = [...misc.telegramUserTransactions, ...action.payload.data];
      misc.telegramUserTransactionsTotalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUserTransactionsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramChallengesRequested: (misc, action) => {
      misc.loadingChallenges = true;
    },
    telegramChallengesReceived: (misc, action) => {
      misc.telegramChallenges = action.payload.data;
      misc.telegramChallengesTotalCount = action.payload.totalCount;
      misc.telegramChallenges?.forEach((challenge) => {
        if (!challenge?.players) {
          challenge.players = [];
        }
        challenge?.players?.sort((a, b) => b?.highestScore - a?.highestScore);
      });
      misc.lastFetch = Date.now();
      misc.loadingChallenges = false;
    },
    telegramChallengesRequestFailed: (misc, action) => {
      misc.loadingChallenges = false;
    },
    //  Prizes
    prizesReceived: (state, action) => {
      state.prizes = action.payload.data;
      state.loading = false;
    },
    addPrizeReceived: (state, action) => {
      state.prizes = [action.payload, ...state.prizes];
      state.loading = false;
    },
    updatePrizeReceived: (state, action) => {
      const ind = state.prizes.findIndex((prize) => prize._id === action.payload._id);
      const newPrizes = state.prizes;
      newPrizes[ind] = { ...newPrizes[ind], ...action.payload };
      state.prizes = [...newPrizes];
    },
    deletePrizeReceived: (state, action) => {
      const ind = state.prizes.findIndex((prize) => prize._id === action.payload._id);
      const newPrizes = state.prizes;
      newPrizes.splice(ind, 1);
      state.prizes = [...newPrizes];
    },
    //  Sweepstakes
    sweepstakeReceived: (state, action) => {
      state.sweepstakes = action.payload.data;
      state.sweepstakePagination = action.payload.pagination;
      state.loading = false;
    },
    addSweepstakeReceived: (state, action) => {
      state.sweepstakes = [action.payload, ...state.sweepstakes];
      state.loading = false;
    },
    updateSweepstakeReceived: (state, action) => {
      const ind = state.sweepstakes.findIndex((sweepstake) => sweepstake._id === action.payload._id);
      const newSweepstakes = state.sweepstakes;
      newSweepstakes[ind] = { ...newSweepstakes[ind], ...action.payload };
      state.sweepstakes = [...newSweepstakes];
    },
    deleteSweepstakeReceived: (state, action) => {
      const ind = state.sweepstakes.findIndex((sweepstake) => sweepstake._id === action.payload._id);
      const newSweepstakes = state.sweepstakes;
      newSweepstakes.splice(ind, 1);
      state.sweepstakes = [...newSweepstakes];
    },
    sweepstakeTemplateReceived: (state, action) => {
      state.sweepstakesTemplate = action.payload.data;
      state.loading = false;
    },
    addSweepstakeTemplateReceived: (state, action) => {
      state.sweepstakesTemplate = [action.payload, ...state.sweepstakesTemplate];
      state.loading = false;
    },
    updateSweepstakeTemplateReceived: (state, action) => {
      const ind = state.sweepstakesTemplate.findIndex((sweepstake) => sweepstake._id === action.payload._id);
      const newSweepstakesTemplate = state.sweepstakesTemplate;
      newSweepstakesTemplate[ind] = { ...newSweepstakesTemplate[ind], ...action.payload };
      state.sweepstakesTemplate = [...newSweepstakesTemplate];
    },
    deleteSweepstakeTemplateReceived: (state, action) => {
      const ind = state.sweepstakesTemplate.findIndex((sweepstake) => sweepstake._id === action.payload._id);
      const newSweepstakesTemplate = state.sweepstakesTemplate;
      newSweepstakesTemplate.splice(ind, 1);
      state.sweepstakesTemplate = [...newSweepstakesTemplate];
    },
    sweepRequested: (state, action) => {
      state.loading = true;
      state.participantsPagination = {};
    },
    sweepRequestedLoadMore: (state, action) => {
      state.loadingMore = true;
    },
    sweepRequestFailed: (state, action) => {
      state.loading = false;
    },
    verisoulMultipleTelegramAccountsRequested: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsLoading = true;
    },
    verisoulMultipleTelegramAccountsReceived: (misc, action) => {
      misc.verisoulMultipleTelegramAccounts = action.payload.data;
      misc.verisoulMultTelegramAccFilteredCount = action.payload.filteredCount;
      misc.verisoulMultTelegramAccTotalCount = action.payload.totalCount;
      misc.verisoulMultipleTelegramAccountsLoading = false;
    },
    verisoulMultipleTelegramAccountsRequestFailed: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsLoading = false;
    },
    verisoulMultipleTelegramAccountsByIdRequested: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsByIdLoading = true;
    },
    verisoulMultipleTelegramAccountsByIdReceived: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsById = action.payload.data;
      misc.verisoulMultipleTelegramAccountsByIdLoading = false;
    },
    verisoulMultipleTelegramAccountsByIdRequestFailed: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsByIdLoading = false;
    },
    verisoulTelegramAuthResultsRequested: (misc, action) => {
      misc.verisoulTelegramAuthResultsLoading = true;
    },
    verisoulTelegramAuthResultsReceived: (misc, action) => {
      misc.verisoulTelegramAuthResults = action.payload.data;
      misc.verisoulTelegramAuthFilteredCount = action.payload.filteredCount;
      misc.verisoulTelegramAuthTotalCount = action.payload.totalCount;
      misc.verisoulTelegramAuthResultsLoading = false;
    },
    verisoulTelegramAuthResultsRequestFailed: (misc, action) => {
      misc.verisoulTelegramAuthResultsLoading = false;
    },
    verisoulAuthResultsByIdRequested: (misc, action) => {
      misc.verisoulAuthResultsByIdLoading = true;
    },
    verisoulAuthResultsByIdReceived: (misc, action) => {
      misc.verisoulAuthResultsById = action.payload.data;
      misc.verisoulAuthResultsByIdTotalCount = action.payload.totalCount;
      misc.verisoulAuthResultsByIdFilteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.verisoulAuthResultsByIdLoading = false;
    },
    verisoulAuthResultsByIdRequestFailed: (misc, action) => {
      misc.verisoulAuthResultsByIdLoading = false;
    },
    updateTelegramUserStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.telegramUsers = misc.telegramUsers.map((user, index) => {
        const newUser = { ...user };
        if (payLoad._id === newUser._id) {
          newUser.status = payLoad.status;
          if (payLoad.status === -1) {
            newUser.suspensionDetails = {
              date: moment.utc().valueOf(),
              reason: payLoad.reason,
            };
          } else if (payLoad.status === 0) {
            newUser.inactiveDetails = {
              date: moment.utc().valueOf(),
              reason: "",
            };
          } else {
            newUser.inactiveDetails = {};
          }
        }
        return newUser;
      });
    },

    sweepstakesParticipantsReceived: (state, action) => {
      state.participants = action.payload.data;
      state.participantsPagination = {
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalEntries: action.payload.totalEntries,
      };
      state.loading = false;
    },

    sweepstakesParticipantsLoadMoreReceived: (state, action) => {
      state.participants = [...state.participants, ...action.payload.data];
      state.participantsPagination = {
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalEntries: action.payload.totalEntries,
      };
      state.loadingMore = false;
    },

    sweepstakesWinnersReceived: (state, action) => {
      state.winners = action.payload.data;
      state.loading = false;
    },
    teamPlayChallengesRequested: (state, action) => {
      state.loading = true;
    },
    teamPlayChallengesReceived: (state, action) => {
      state.teamPlayChallenges = action.payload.data;
      state.teamPlayTotalCount = action.payload.totalCount;
      state.loading = false;
    },
    teamPlayChallengesRequestFailed: (state, action) => {
      state.loading = false;
    },
    addTeamPlayChallengeReceived: (state, action) => {
      state.teamPlayChallenges = [action.payload, ...state.teamPlayChallenges];
      state.loading = false;
    },
    updateTeamPlayChallengeReceived: (state, action) => {
      const ind = state.teamPlayChallenges.findIndex((challenge) => challenge._id === action.payload._id);
      const newChallenges = state.teamPlayChallenges;
      newChallenges[ind] = { ...newChallenges[ind], ...action.payload };
      state.teamPlayChallenges = [...newChallenges];
    },
    deleteTeamPlayChallengeReceived: (state, action) => {
      const ind = state.teamPlayChallenges.findIndex((challenge) => challenge._id === action.payload._id);
      const newChallenges = state.teamPlayChallenges;
      newChallenges.splice(ind, 1);
      state.teamPlayChallenges = [...newChallenges];
    },
    questsRequested: (misc, action) => {
      misc.questsLoading = true;
    },
    questsReceived: (misc, action) => {
      misc.quests = action.payload.data;
      misc.quests?.sort((a, b) => a?.displayOrder - b?.displayOrder);
      misc.questsLoading = false;
    },
    questsRequestFailed: (misc, action) => {
      misc.questsLoading = false;
    },
    dailyTournamentRequested: (misc, action) => {
      misc.dailyTournamentLoading = true;
    },
    dailyTournamentReceived: (misc, action) => {
      misc.dailyTournament = action.payload.data;
      misc.dailyTournamentLoading = false;
    },
    dailyTournamentRequestFailed: (misc, action) => {
      misc.dailyTournamentLoading = false;
    },
    dailyTournamentLeaderboardRequested: (misc, action) => {
      misc.dailyTournamentLeaderboardLoading = true;
    },
    dailyTournamentLeaderboardReceived: (misc, action) => {
      misc.dailyTournamentLeaderboard = action.payload.data;
      misc.dailyTournamentLeaderboardLoading = false;
    },
    dailyTournamentLeaderboardRequestFailed: (misc, action) => {
      misc.dailyTournamentLeaderboardLoading = false;
    },
    telegramGamesRequested: (misc, action) => {
      misc.telegramGamesLoading = true;
    },
    telegramGamesReceived: (misc, action) => {
      misc.telegramGames = action.payload.data;
      misc.telegramGamesTotalCount = action.payload.totalCount;
      misc.telegramGamesLoading = false;
    },
    telegramGamesRequestFailed: (misc, action) => {
      misc.telegramGamesLoading = false;
    },
    teammatesRequested: (state, action) => {
      state.loadingTeammates = true;
    },
    teammatesReceived: (state, action) => {
      state.teammates = action.payload.data;
      state.loadingTeammates = false;
      state.teammatesFilteredCount = action.payload.filteredCount;
      state.teammatesTotalCount = action.payload.totalCount;
    },
    teammatesRequestFailed: (state, action) => {
      state.loadingTeammates = false;
    },
    moreTeammatesReceived: (state, action) => {
      state.teammates = [...state.teammates, ...action.payload.data];
      state.teammatesFilteredCount = action.payload.filteredCount;
      state.teammatesTotalCount = action.payload.totalCount;
      state.loadingTeammates = false;
    },
  },
});

export const {
  telegramSpinRewardRequested,
  telegramSpinRewardReceived,
  telegramSpinRewardRequestFailed,
  telegramUsdtWithdrawalsReceived,
  telegramUsdtWithdrawalsRequestFailed,
  telegramUsdtWithdrawalsRequested,
  telegramUsdtStatusReceived,
  telegramSettingRequested,
  telegramSettingReceived,
  telegramSettingRequestFailed,
  telegramUsersRequested,
  telegramUsersReceived,
  telegramUsersRequestFailed,
  telegramUserTransactionsRequested,
  telegramUserTransactionsReceived,
  moreTelegramUserTransactionsReceived,
  telegramUserTransactionsRequestFailed,
  telegramChallengesRequested,
  telegramChallengesReceived,
  prizesReceived,
  addPrizeReceived,
  updatePrizeReceived,
  deletePrizeReceived,
  sweepRequested,
  sweepRequestFailed,
  sweepstakeReceived,
  addSweepstakeReceived,
  updateSweepstakeReceived,
  deleteSweepstakeReceived,
  telegramChallengesRequestFailed,
  verisoulTelegramAuthResultsRequested,
  verisoulTelegramAuthResultsReceived,
  verisoulTelegramAuthResultsRequestFailed,
  verisoulMultipleTelegramAccountsRequested,
  verisoulMultipleTelegramAccountsReceived,
  verisoulMultipleTelegramAccountsRequestFailed,
  verisoulMultipleTelegramAccountsByIdRequested,
  verisoulMultipleTelegramAccountsByIdReceived,
  verisoulMultipleTelegramAccountsByIdRequestFailed,
  updateTelegramUserStatusReceived,
  sweepstakeTemplateReceived,
  addSweepstakeTemplateReceived,
  updateSweepstakeTemplateReceived,
  deleteSweepstakeTemplateReceived,
  sweepstakesParticipantsReceived,
  sweepstakesWinnersReceived,
  teamPlayChallengesRequested,
  teamPlayChallengesReceived,
  teamPlayChallengesRequestFailed,
  addTeamPlayChallengeReceived,
  updateTeamPlayChallengeReceived,
  deleteTeamPlayChallengeReceived,
  verisoulAuthResultsByIdRequested,
  verisoulAuthResultsByIdReceived,
  verisoulAuthResultsByIdRequestFailed,
  questsRequested,
  questsReceived,
  questsRequestFailed,
  dailyTournamentRequested,
  dailyTournamentReceived,
  dailyTournamentRequestFailed,
  sweepstakesParticipantsLoadMoreReceived,
  sweepRequestedLoadMore,
  dailyTournamentLeaderboardRequested,
  dailyTournamentLeaderboardReceived,
  dailyTournamentLeaderboardRequestFailed,
  telegramGamesRequested,
  telegramGamesReceived,
  telegramGamesRequestFailed,
  teammatesRequested,
  teammatesReceived,
  teammatesRequestFailed,
  moreTeammatesReceived,
} = slice.actions;
export default slice.reducer;

// Action Creators
const telegramSweepstakePrizeURL = "telegramSweepstakes/sweepstakesTier/";
const telegramSweepstakesURL = "telegramSweepstakes/sweepstakes/";
const telegramSweepstakesTemplateURL = "telegramSweepstakes/sweepstakesTemplate/";

export const loadTelegramSpinReward = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSpinReward",
      params,
      onStart: telegramSpinRewardRequested.type,
      onSuccess: telegramSpinRewardReceived.type,
      onError: telegramSpinRewardRequestFailed.type,
      callback,
    }),
  );
};
export const updateTelegramSpinReward = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSpinReward/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const loadTelegramUsdtRequests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/usdtWithdrawal",
      params,
      onStart: telegramUsdtWithdrawalsRequested.type,
      onSuccess: telegramUsdtWithdrawalsReceived.type,
      onError: telegramUsdtWithdrawalsRequestFailed.type,
      callback,
    }),
  );
};
export const updateTelegramUsdtStatus = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/transferUsdt/" + id,
      callback,
    }),
  );
};
export const loadTelegramSettings = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSetting",
      onStart: telegramSettingRequested.type,
      onSuccess: telegramSettingReceived.type,
      onError: telegramSettingRequestFailed.type,
      callback,
    }),
  );
};
export const addTelegramSetting = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSetting",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadTelegramUsers = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser",
      params,
      onStart: telegramUsersRequested.type,
      onSuccess: telegramUsersReceived.type,
      onError: telegramUsersRequestFailed.type,
      callback,
    }),
  );
};
export const updateTelegramUserStatus = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/updateStatus/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadTelegramUserTransactions = (id, params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/transactions/" + id,
      params,
      onStart: telegramUserTransactionsRequested.type,
      onSuccess: fresh ? telegramUserTransactionsReceived.type : moreTelegramUserTransactionsReceived.type,
      onError: telegramUserTransactionsRequestFailed.type,
      callback,
    }),
  );
};
export const loadTelegramChallenges = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/challenges",
      params,
      onStart: telegramChallengesRequested.type,
      onSuccess: telegramChallengesReceived.type,
      onError: telegramChallengesRequestFailed.type,
      callback,
    }),
  );
};

//  ===>>> Prizes Start
export const loadPrizes = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakePrizeURL,
      onStart: sweepRequested.type,
      onSuccess: prizesReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addPrizes = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakePrizeURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updatePrizes = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakePrizeURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deletePrizes = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakePrizeURL + id,
      method: "DELETE",
      callback,
    }),
  );
};
//  ===>>> Prizes End

//  ===>>> Sweepstakes Start
export const loadSweepstakes = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesURL,
      params,
      onStart: sweepRequested.type,
      onSuccess: sweepstakeReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addSweepstakes = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateSweepstakes = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteSweepstakes = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesURL + id,
      method: "DELETE",
      callback,
    }),
  );
};

export const loadSweepstakesTemplate = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesTemplateURL,
      params,
      onStart: sweepRequested.type,
      onSuccess: sweepstakeTemplateReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addSweepstakesTemplate = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesTemplateURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateSweepstakesTemplate = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesTemplateURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteSweepstakesTemplate = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: telegramSweepstakesTemplateURL + id,
      method: "DELETE",
      callback,
    }),
  );
};
//  ===>>> Sweepstakes End

export const loadVerisoulTelegramAuthResults = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "telegramUser/verisoulAuthResults",

      onStart: verisoulTelegramAuthResultsRequested.type,
      onSuccess: verisoulTelegramAuthResultsReceived.type,
      onError: verisoulTelegramAuthResultsRequestFailed.type,
      callback,
    }),
  );
};

export const loadVerisoulAuthResultsById = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "telegramUser/verisoulAuthResults/" + id,
      onStart: verisoulAuthResultsByIdRequested.type,
      onSuccess: verisoulAuthResultsByIdReceived.type,
      onError: verisoulAuthResultsByIdRequestFailed.type,
      callback,
    }),
  );
};
export const loadVerisoulMultipleTelegramAccounts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "telegramUser/verisoulMultipleAccounts",
      onStart: verisoulMultipleTelegramAccountsRequested.type,
      onSuccess: verisoulMultipleTelegramAccountsReceived.type,
      onError: verisoulMultipleTelegramAccountsRequestFailed.type,
      callback,
    }),
  );
};
export const loadVerisoulMultipleTelegramAccountsById = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/verisoulMultipleAccounts/" + id,
      onStart: verisoulMultipleTelegramAccountsByIdRequested.type,
      onSuccess: verisoulMultipleTelegramAccountsByIdReceived.type,
      onError: verisoulMultipleTelegramAccountsByIdRequestFailed.type,
      callback,
    }),
  );
};
export const loadSweepstakesParticipants = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSweepstakes/sweepstakes/entries/" + id + "?page=1",
      onStart: sweepRequested.type,
      onSuccess: sweepstakesParticipantsReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};

export const loadSweepstakesParticipantsLoadMore = (id, page, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSweepstakes/sweepstakes/entries/" + id + "?page=" + page,
      onStart: sweepRequestedLoadMore.type,
      onSuccess: sweepstakesParticipantsLoadMoreReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};

export const loadSweepstakesWinners = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSweepstakes/sweepstakes/winners/" + id,
      onStart: sweepRequested.type,
      onSuccess: sweepstakesWinnersReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};

// Notifications
export const sendUserNotifications = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/sendMessage",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const sendBroadcastNotifications = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/sendMessageToAllUsers",
      method: "POST",
      data,
      callback,
    }),
  );
};

// Team Play Challenges
export const loadTeamPlayChallenges = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramTeamPlayChallenge/",
      params,
      onStart: teamPlayChallengesRequested.type,
      onSuccess: teamPlayChallengesReceived.type,
      onError: teamPlayChallengesRequestFailed.type,
    }),
  );
};
export const addTeamPlayChallenge = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramTeamPlayChallenge/",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deleteTeamPlayChallenge = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramTeamPlayChallenge/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const updateTeamPlayChallenge = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramTeamPlayChallenge/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};

//Quests
export const loadQuests = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramQuest",
      onStart: questsRequested.type,
      onSuccess: questsReceived.type,
      onError: questsRequestFailed.type,
      callback,
    }),
  );
};

export const addQuest = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramQuest",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const updateQuest = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramQuest/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const deleteQuest = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramQuest/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadDailyTournament = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramDailyContest",
      params,
      onStart: dailyTournamentRequested.type,
      onSuccess: dailyTournamentReceived.type,
      onError: dailyTournamentRequestFailed.type,
      callback,
    }),
  );
};
export const addDailyTournament = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramDailyContest",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateDailyTournament = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramDailyContest/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const loadDailyTournamentLeaderboard = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramDailyContest/leaderboard/" + id,
      params,
      onStart: dailyTournamentLeaderboardRequested.type,
      onSuccess: dailyTournamentLeaderboardReceived.type,
      onError: dailyTournamentLeaderboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadTelegramGames = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramBgGame/userGameMetaData",
      params,
      onStart: telegramGamesRequested.type,
      onSuccess: telegramGamesReceived.type,
      onError: telegramGamesRequestFailed.type,
      callback,
    }),
  );
};
export const loadTeammates = (id, params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/teammates/" + id,
      params,
      onStart: teammatesRequested.type,
      onSuccess: fresh ? teammatesReceived.type : moreTeammatesReceived.type,
      onError: teammatesRequestFailed.type,
      callback,
    }),
  );
};
export const getTelegram = createSelector(
  (state) => state.entities.telegram,
  (telegram) => telegram,
);
