import _ from "lodash";
import moment from "moment";
import { Component, createRef } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteSweepstakes,
  deleteSweepstakeReceived,
  getTelegram,
  loadSweepstakes,
  updateSweepstakeReceived,
  updateSweepstakes,
} from "store/telegram";
import Joi from "joi-browser";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import exportIcon from "include/images/export.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";
import filterIcon from "include/images/filter-icon.svg";
import closeIcon from "include/images/close-icon.svg";

//  Components
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import AddSweepstake from "./addSweepstake";
import { CSVLink } from "react-csv";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import ParticipantsListing from "./participantsListing";
import WinnersListing from "./winnersListing";
import { TELEGRAM_SWEEPSTAKES_PRIZE_TYPES } from "utils/constants";

class SweepstakesListing extends Form {
  ref = createRef(null);
  refCsv = createRef(null);
  state = {
    data: {
      title: "",
      winnerUserName: "",
    },
    id: "",
    loadingOptions: false,
    loadingMain: false,
    loadingDelete: false,
    loading: false,
    showDeleteModal: false,
    showAddModal: false,
    cancelId: "",
    editData: null,
    showHistory: false,
    objectId: "",
    object: null,
    page: 1,
    statusInd: "",
    loadingStatus: false,
    showLeaderboard: false,
    showWinners: false,
    instance: null,
    winnersCSV: [],
    loadingCsv: false,
    sweeps: null,
    errors: {},
  };
  schema = {
    title: Joi.string().allow(""),
    winnerUserName: Joi.string().allow(""),
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.complete();

    if (this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.continuousStart();

    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
    const params = new URLSearchParams(this.props.location.search);
    const status = params.get("status");
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevStatus = prevParams.get("status");
    if (status !== prevStatus) this.getInstances();
  };
  componentDidMount = () => {
    this.getInstances();
  };
  getInstances = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const status = searchParams.get("status");
    const page = this.state.page;
    this.setState({ loadingMain: true });
    this.props.loadSweepstakes({ page, status }, (res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  toggleLeaderboardModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showLeaderboard: !this.state.showLeaderboard });
  };
  toggleWinnersModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showWinners: !this.state.showWinners });
  };
  onChange = (e) => {
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };
  handleCancel = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteSweepstakes(this.state.cancelId, (instanceRes) => {
      if (instanceRes.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deleteSweepstakeReceived(instanceRes?.data?.data);
        this.cancelModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={instanceRes.data && instanceRes.data.message} />, {
          containerId: 1,
        });
        this.cancelModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };
  changeStatus = (e) => {
    this.setState({ loadingStatus: true });
    const payLoad = { status: this.state.statusInd };
    delete payLoad._id;
    this.props.updateSweepstakes(this.state.statusChangeId, payLoad, (contractRes) => {
      this.setState({
        loadingStatus: false,
      });
      if (contractRes.status === 200) {
        this.props.updateSweepstakeReceived(contractRes.data.data);
      } else {
        toast(<AlertError message={contractRes.data && contractRes.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  cancelModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  searchFilters = (e) => {
    if (e) e.preventDefault();

    this.setState({ loadingFilters: true });
    var params = _.pickBy(this.state.data, function (value, key) {
      return !_.isEmpty(value);
    });
    params.page = this.state.page;
    this.props.loadSweepstakes(params, (instanceRes) => {
      this.setState({
        loadingFilters: false,
      });
      if (instanceRes?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters });
      }
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          title: "",
          winnerUserName: "",
        },
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (type) => {
    const data = this.state.data;
    data[type] = "";
    this.setState({ data }, this.searchFilters);
  };
  getOrdinalSuffix(number) {
    const remainder100 = number % 100;
    const remainder10 = number % 10;

    // Handle special cases for 11, 12, and 13
    if (remainder100 >= 11 && remainder100 <= 13) {
      return `${number}th`;
    }

    // Handle general cases based on last digit
    switch (remainder10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const status = params.get("status");
    const sweepstakes = this.props.getTelegram?.sweepstakes;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-sweepstake" />
          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1 className="camelCase">
                  {status ? (status === "ended" ? "Completed" : status === "cancelled" ? "Canceled" : status) : "All"}{" "}
                  Sweepstakes
                </h1>
                {(!status || status === "scheduled") && (
                  <div className="filter-menu-box d-flex align-items-center ms-auto">
                    <div className="fmb-box">
                      <Link
                        to="#"
                        onClick={this.toggleAddModal}
                        className="btn btn-default btn-sm d-flex justify-content-center"
                      >
                        <img src={plusIcon} alt="" /> Add a Sweepstakes
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {sweepstakes.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center", width: 40 }}>Actions</th>
                              <th style={{ width: "12%" }}>Title</th>
                              <th style={{ width: "8%" }}>Winner Declared</th>
                              <th>Sweeps Image</th>
                              <th>Start Date & Time</th>
                              <th>Expiry Date & Time</th>
                              <th>Min Entry</th>
                              <th>Sweeps Prize</th>
                              <th>Status</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sweepstakes?.length > 0 &&
                              sweepstakes.map((instance, ind) => {
                                return (
                                  <tr>
                                    <td>
                                      <Dropdown className=" custom-dropdown">
                                        <Dropdown.Toggle
                                          id="tdDropdownOne"
                                          className={`custom-dropdown-toggle`}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={carrentIcon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className={`dropdown-menu-start `}
                                          aria-labelledby="tdDropdownOne"
                                        >
                                          {instance.status === "scheduled" && (
                                            <Dropdown.Item
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ editData: instance }, () => {
                                                  this.toggleAddModal();
                                                });
                                              }}
                                            >
                                              Edit
                                            </Dropdown.Item>
                                          )}
                                          {instance.status !== "scheduled" && (
                                            <Dropdown.Item
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ instance }, () => {
                                                  this.toggleLeaderboardModal();
                                                });
                                              }}
                                            >
                                              View Participants
                                            </Dropdown.Item>
                                          )}
                                          {instance.status === "ended" && instance.isWinnerDeclared && (
                                            <Dropdown.Item
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ instance }, () => {
                                                  this.toggleWinnersModal();
                                                });
                                              }}
                                            >
                                              View Winners
                                            </Dropdown.Item>
                                          )}
                                          {instance.status === "scheduled" && (
                                            <Dropdown.Item
                                              className="dropdown-item delete"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ cancelId: instance?._id }, () => {
                                                  this.cancelModalToggle();
                                                });
                                              }}
                                            >
                                              Delete
                                            </Dropdown.Item>
                                          )}
                                          {(instance?.status === "active" || instance?.status === "paused") && (
                                            <Dropdown.Item
                                              className="dropdown-item delete"
                                              onClick={(e) => {
                                                this.setState(
                                                  {
                                                    statusChangeId: instance._id,
                                                    sweeps: instance,
                                                    statusInd: "cancelled",
                                                    loadingStatus: true,
                                                  },
                                                  this.changeStatus,
                                                );
                                              }}
                                            >
                                              Cancel
                                            </Dropdown.Item>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                    <td>
                                      {instance?.status === "scheduled" ? (
                                        <Link
                                          to={`!#`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ editData: instance }, () => {
                                              this.toggleAddModal();
                                            });
                                          }}
                                        >
                                          <div class="small-text">{instance?.name}</div>
                                        </Link>
                                      ) : (
                                        <div class="small-text">{instance?.name}</div>
                                      )}
                                    </td>
                                    <td>{instance?.isWinnerDeclared ? "Yes" : "No"}</td>
                                    <td>
                                      <div className="td-img margin-right-2 ">
                                        <img src={process.env.REACT_APP_CDN + instance?.image} alt="" />
                                      </div>
                                    </td>
                                    <td>
                                      <div class="small-text">
                                        {moment(instance?.startDate).format("MM/DD/YYYY")}
                                        <br /> <small> {moment(instance?.startDate).format("hh:mm a")}</small>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="small-text">
                                        {moment(instance?.endDate).format("MM/DD/YYYY")}
                                        <br /> <small> {moment(instance?.endDate).format("hh:mm a")}</small>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="small-text">
                                        {instance?.minEntry?.toLocaleString("en-us")}{" "}
                                        {instance?.minEntry > 1 ? "Points" : "Point"}
                                      </div>
                                    </td>
                                    <td>
                                      {instance?.tiers?.map((tier) => (
                                        <div className="camelCase">
                                          {tier.startRank === tier.endRank ? (
                                            <>{this.getOrdinalSuffix(tier.startRank)}</>
                                          ) : (
                                            <>
                                              {this.getOrdinalSuffix(tier.startRank)} -{" "}
                                              {this.getOrdinalSuffix(tier.endRank)}
                                            </>
                                          )}
                                          :{" "}
                                          {tier?.prizes?.map((p, i) => (
                                            <>
                                              {p.quantity} {TELEGRAM_SWEEPSTAKES_PRIZE_TYPES[p.prizeType]}
                                              {p.prizeType !== "usdt" && p.quantity > 1 && "s"}
                                              {i < tier.prizes?.length - 1 && ", "}
                                            </>
                                          ))}
                                        </div>
                                      ))}
                                    </td>
                                    <td className="ust-tag">
                                      <Dropdown className=" custom-dropdown">
                                        <Dropdown.Toggle
                                          id="tdDropdownOne"
                                          className="custom-dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          disabled={instance?.status !== "active" && instance?.status !== "paused"}
                                        >
                                          <span
                                            className={`w-100 text-center d-block camelCase ${
                                              instance.status === "ended" || instance.status === "active"
                                                ? "active"
                                                : instance.status === "cancelled"
                                                ? "suspended"
                                                : "inactive"
                                            } ${this.state.loadingStatus && "pointer-none"}`}
                                          >
                                            {this.state.loadingStatus && this.state.statusChangeId === instance._id ? (
                                              <img
                                                src={loadingIcon}
                                                alt="loading..."
                                                style={{ width: 20 }}
                                                className="fa-spin"
                                              />
                                            ) : instance.status === "ended" ? (
                                              "Completed"
                                            ) : (
                                              instance.status
                                            )}
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className={`dropdown-menu-start `}
                                          aria-labelledby="tdDropdownOne"
                                        >
                                          <>
                                            <Dropdown.Item
                                              className="dropdown-item "
                                              onClick={(e) => {
                                                this.setState(
                                                  {
                                                    statusChangeId: instance._id,
                                                    sweeps: instance,
                                                    statusInd: instance.status === "active" ? "paused" : "active",
                                                    loadingStatus: true,
                                                  },
                                                  this.changeStatus,
                                                );
                                              }}
                                            >
                                              {instance?.status === "active" ? "Pause" : "Active"}
                                            </Dropdown.Item>
                                            {(instance?.status === "active" || instance?.status === "paused") && (
                                              <Dropdown.Item
                                                className="dropdown-item "
                                                onClick={(e) => {
                                                  this.setState(
                                                    {
                                                      statusChangeId: instance._id,
                                                      sweeps: instance,
                                                      statusInd: "cancelled",
                                                      loadingStatus: true,
                                                    },
                                                    this.changeStatus,
                                                  );
                                                }}
                                              >
                                                Cancel
                                              </Dropdown.Item>
                                            )}
                                          </>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>{" "}
                                    <td>
                                      <div class="small-text">
                                        {moment(instance?.createdAt).format("MM/DD/YYYY")}
                                        <br /> <small> {moment(instance?.createdAt).format("hh:mm a")}</small>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {!this.state.loading && !(this.state.page === 1 && sweepstakes.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1, currKey: this.lastKeys.pop() }, () =>
                                  this.searchFilters(),
                                );
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {sweepstakes.length >= 50 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.lastKeys.push(this.state.currKey);
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${sweepstakes.length < 50 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.lastKeys.push(this.state.currKey);
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddSweepstake
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadSweepstakes}
          edit={this.state.editData}
        />
        <DeleteModal
          alertModalToggle={this.cancelModalToggle}
          alertModalShow={this.state.showDeleteModal}
          alertType="Yes"
          title="Alert"
          description="Are you sure you want to delete this sweepstake?"
          onPress={this.handleCancel}
          loading={this.state.loadingDelete}
        />
        <ParticipantsListing
          showModal={this.state.showLeaderboard}
          toggleModal={this.toggleLeaderboardModal}
          instance={this.state.instance}
        />
        <WinnersListing
          showModal={this.state.showWinners}
          toggleModal={this.toggleWinnersModal}
          instance={this.state.instance}
        />
        {(this.state.showAddModal || this.state.showLeaderboard || this.state.showWinners) && (
          <div
            className="modal-backdrop show fade"
            onClick={
              this.state.showLeaderboard
                ? this.toggleLeaderboardModal
                : this.state.showWinners
                ? this.toggleWinnersModal
                : this.toggleAddModal
            }
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadSweepstakes: (params, callback) => dispatch(loadSweepstakes(params, callback)),
  deleteSweepstakes: (id, callback) => dispatch(deleteSweepstakes(id, callback)),
  deleteSweepstakeReceived: (payLoad) => dispatch(deleteSweepstakeReceived(payLoad)),
  updateSweepstakeReceived: (payLoad) => dispatch(updateSweepstakeReceived(payLoad)),
  updateSweepstakes: (id, data, callback) => dispatch(updateSweepstakes(id, data, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SweepstakesListing));
