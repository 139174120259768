import Joi from "joi-browser";
import _, { isEmpty } from "lodash";
import moment from "moment";
import { createRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getTelegram,
  loadTeamPlayChallenges,
  updateTeamPlayChallenge,
  updateTeamPlayChallengeReceived,
  deleteTeamPlayChallenge,
  deleteTeamPlayChallengeReceived,
} from "store/telegram";

//  Images
import closeIcon from "include/images/close-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import carrentIcon from "include/images/carrent-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import AddTeamPlayChallenge from "./addTeamPlayChallenge";
import { TELEGRAM_TEAM_PLAY_PRIZE_TYPES } from "utils/constants";
import { Dropdown } from "react-bootstrap";
import AlertModal from "common/alert/alertModal";

class SweepstakesListing extends Form {
  ref = createRef(null);
  refCsv = createRef(null);
  state = {
    data: {
      title: "",
      winnerUserName: "",
    },
    id: "",
    loadingOptions: false,
    loadingMain: false,
    loadingDelete: false,
    loading: false,
    showDeleteModal: false,
    showAddModal: false,
    cancelId: "",
    editData: null,
    showHistory: false,
    objectId: "",
    object: null,
    page: 1,
    statusInd: "",
    loadingStatus: false,
    showLeaderboard: false,
    showWinners: false,
    challenge: null,
    winnersCSV: [],
    loadingCsv: false,
    sweeps: null,
    errors: {},
  };
  schema = {
    title: Joi.string().allow(""),
    winnerUserName: Joi.string().allow(""),
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.complete();

    if (this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.continuousStart();

    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
    const params = new URLSearchParams(this.props.location.search);
    const status = params.get("status");
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevStatus = prevParams.get("status");
    if (status !== prevStatus) this.getTeamPlayChallenges();
  };
  componentDidMount = () => {
    this.getTeamPlayChallenges();
  };
  getTeamPlayChallenges = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const status = searchParams.get("status");
    const page = this.state.page;
    this.setState({ loadingMain: true });
    this.props.loadTeamPlayChallenges({ page, status }, (res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  toggleLeaderboardModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showLeaderboard: !this.state.showLeaderboard });
  };
  toggleWinnersModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showWinners: !this.state.showWinners });
  };
  onChange = (e) => {
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };
  handleCancel = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteTeamPlayChallenge(this.state.cancelId, (challengeRes) => {
      if (challengeRes.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deleteTeamPlayChallengeReceived(challengeRes?.data?.data);
        this.cancelModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={challengeRes.data && challengeRes.data.message} />, {
          containerId: 1,
        });
        this.cancelModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };
  changeStatus = (e) => {
    this.setState({ loadingStatus: true });
    const payLoad = { status: this.state.statusInd };
    delete payLoad._id;
    this.props.updateTeamPlayChallenge(this.state.statusChangeId, payLoad, (statusRes) => {
      this.setState({
        loadingStatus: false,
      });
      if (statusRes.status === 200) {
        this.props.updateTeamPlayChallengeReceived(statusRes.data.data);
      } else {
        toast(<AlertError message={statusRes.data && statusRes.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  cancelModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  searchFilters = (e) => {
    if (e) e.preventDefault();

    this.setState({ loadingFilters: true });
    var params = _.pickBy(this.state.data, function (value, key) {
      return !_.isEmpty(value);
    });
    params.page = this.state.page;
    this.props.loadTeamPlayChallenges(params, (challengeRes) => {
      this.setState({
        loadingFilters: false,
      });
      if (challengeRes?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters });
      }
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          title: "",
          winnerUserName: "",
        },
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (type) => {
    const data = this.state.data;
    data[type] = "";
    this.setState({ data }, this.searchFilters);
  };
  getOrdinalSuffix(number) {
    const remainder100 = number % 100;
    const remainder10 = number % 10;

    // Handle special cases for 11, 12, and 13
    if (remainder100 >= 11 && remainder100 <= 13) {
      return `${number}th`;
    }

    // Handle general cases based on last digit
    switch (remainder10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }

  render() {
    const { teamPlayChallenges, totalChallengesCount } = this.props.getTelegram;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-team-play" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Team Play Challenges {totalChallengesCount > 0 && `(${totalChallengesCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {/* <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div> */}
                <div className="fmb-box">
                  <Link
                    to="#"
                    onClick={this.toggleAddModal}
                    className="btn btn-default btn-sm d-flex justify-content-center"
                  >
                    <img src={plusIcon} alt="" /> Add a Team Play Challenge
                  </Link>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("bgGameName", "BG Game Name")}</div>
                      </li>{" "}
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("playerOneUserName", "Player One Username")}</div>
                      </li>{" "}
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("playerTwoUserName", "Player Two Username")}</div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => {
                          this.setState(
                            {
                              page: 1,
                            },
                            () => this.searchFilters(),
                          );
                        }}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "bgGameName"
                                  ? "Bg Game Name"
                                  : key === "playerOneUserName"
                                  ? "Player One User Name"
                                  : key === "playerTwoUserName"
                                  ? "Player Two User Name"
                                  : key}
                              </span>{" "}
                              :{" "}
                              {key === "status" ? this.state.appliedFilters[key].name : this.state.appliedFilters[key]}
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      data: { ...this.state.data, [key]: "" },
                                      page: 1,
                                    },
                                    this.searchFilters,
                                  );
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}{" "}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {teamPlayChallenges?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center", width: 40 }}>Actions</th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>No. of Games</th>
                            <th>Prize</th>
                            <th style={{ width: 80 }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teamPlayChallenges?.map((challenge, i) => (
                            <tr>
                              <td>
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className={`custom-dropdown-toggle`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={challenge?.status !== "scheduled"}
                                  >
                                    <img src={carrentIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ editData: challenge }, () => {
                                          this.toggleAddModal();
                                        });
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="dropdown-item delete"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ cancelId: challenge?._id }, () => {
                                          this.cancelModalToggle();
                                        });
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Link
                                  to="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ editData: challenge }, () => {
                                      this.toggleAddModal();
                                    });
                                  }}
                                  className={`td-text-box d-flex align-items-center ${
                                    challenge?.status !== "scheduled" && "pointer-none"
                                  }`}
                                >
                                  {challenge?.title}
                                </Link>
                              </td>
                              <td>
                                <div className="td-text-box d-flex align-items-center">
                                  <div className="td-img margin-right-2 pointer">
                                    {challenge?.image && (
                                      <img src={process.env.REACT_APP_CDN + challenge?.image} alt="" />
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="small-text">
                                  {challenge?.startDate && moment(challenge?.startDate).format("MM/DD/YYYY")}
                                  <br />{" "}
                                  <small>
                                    {challenge?.startDate && moment(challenge?.startDate).format("hh:mm a")}
                                  </small>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div class="small-text">
                                  {challenge?.endDate && moment(challenge?.endDate).format("MM/DD/YYYY")}
                                  <br />{" "}
                                  <small>{challenge?.endDate && moment(challenge?.endDate).format("hh:mm a")}</small>
                                </div>
                              </td>
                              <td>{challenge?.numberOfGames}</td>

                              <td>
                                {challenge?.prizeValue} {TELEGRAM_TEAM_PLAY_PRIZE_TYPES[challenge?.prizeType]}
                                {challenge?.prizeValue > 1 && challenge.prizeType !== 2 ? "s" : ""}
                              </td>
                              <td className="ust-tag">
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={challenge?.status !== "active" && challenge?.status !== "paused"}
                                  >
                                    <span
                                      className={`w-100 text-center d-block camelCase ${
                                        challenge.status === "ended" || challenge.status === "active"
                                          ? "active"
                                          : challenge.status === "cancelled"
                                          ? "suspended"
                                          : "inactive"
                                      } ${this.state.loadingStatus && "pointer-none"}`}
                                    >
                                      {this.state.loadingStatus && this.state.statusChangeId === challenge._id ? (
                                        <img
                                          src={loadingIcon}
                                          alt="loading..."
                                          style={{ width: 20 }}
                                          className="fa-spin"
                                        />
                                      ) : challenge.status === "ended" ? (
                                        "Completed"
                                      ) : (
                                        challenge.status
                                      )}
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <>
                                      <Dropdown.Item
                                        className="dropdown-item "
                                        onClick={(e) => {
                                          this.setState(
                                            {
                                              statusChangeId: challenge._id,
                                              sweeps: challenge,
                                              statusInd: challenge.status === "active" ? "paused" : "active",
                                              loadingStatus: true,
                                            },
                                            this.changeStatus,
                                          );
                                        }}
                                      >
                                        {challenge?.status === "active" ? "Pause" : "Active"}
                                      </Dropdown.Item>
                                      {(challenge?.status === "active" || challenge?.status === "paused") && (
                                        <Dropdown.Item
                                          className="dropdown-item "
                                          onClick={(e) => {
                                            this.setState(
                                              {
                                                statusChangeId: challenge._id,
                                                sweeps: challenge,
                                                statusInd: "cancelled",
                                                loadingStatus: true,
                                              },
                                              this.changeStatus,
                                            );
                                          }}
                                        >
                                          Cancel
                                        </Dropdown.Item>
                                      )}
                                    </>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
                {!this.state.loading &&
                  !(this.state.page === 1 && teamPlayChallenges?.length === 0) &&
                  !(teamPlayChallenges?.length < 50 && this.state.page === 1) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {teamPlayChallenges?.length >= 50 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${teamPlayChallenges?.length < 50 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <AddTeamPlayChallenge
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadTeamPlayChallenges}
          edit={this.state.editData}
        />
        <AlertModal
          alertModalToggle={this.cancelModalToggle}
          alertModalShow={this.state.showDeleteModal}
          alertType="Yes"
          title="Alert"
          description="Are you sure you want to delete this Team Play Challenge?"
          onPress={this.handleCancel}
          loading={this.state.loadingDelete}
        />
        {this.state.showAddModal && <div className="modal-backdrop show fade" onClick={this.toggleAddModal}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadTeamPlayChallenges: (params, callback) => dispatch(loadTeamPlayChallenges(params, callback)),
  updateTeamPlayChallengeReceived: (payLoad) => dispatch(updateTeamPlayChallengeReceived(payLoad)),
  updateTeamPlayChallenge: (id, data, callback) => dispatch(updateTeamPlayChallenge(id, data, callback)),
  deleteTeamPlayChallenge: (id, callback) => dispatch(deleteTeamPlayChallenge(id, callback)),
  deleteTeamPlayChallengeReceived: (payLoad) => dispatch(deleteTeamPlayChallengeReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SweepstakesListing));
