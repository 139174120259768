import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import userIcon from "include/images/user.png";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { sendBroadcastNotifications, sendUserNotifications } from "store/telegram";

class SendNotificationFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    loading: false,
    data: {
      message: EditorState.createEmpty(),
    },
    errors: {},
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
  };
  doSubmit = (e) => {
    const data = { ...this.state.data };
    // Check if the message content is empty or contains only whitespace
    if (
      !data?.message?.getCurrentContent()?.hasText() ||
      data?.message?.getCurrentContent()?.getPlainText("")?.trim()?.length === 0
    ) {
      this.setState({ errors: { message: "Message is required" } });
      return;
    }
    this.setState({ loading: true });

    const payLoad = {
      message: stateToHTML(data?.message?.getCurrentContent(), {
        defaultBlockTag: null,
      })?.replace(/&nbsp;/g, ""),
    };

    if (this.props?.user === "all") {
      this.props.sendBroadcastNotifications(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res?.status === 200) {
          toast(<AlertSuccess message="Notification sent successfully" />, {
            containerId: 1,
          });
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      payLoad.telegramIds = [this.props?.user?.telegramId];

      this.props.sendUserNotifications(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res?.status === 200) {
          toast(<AlertSuccess message="Notification sent successfully" />, {
            containerId: 1,
          });
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    message: Joi.object().required(),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        message: EditorState.createEmpty(),
      },
      errors: {},
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  render() {
    const user = this.props.user;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <h5>{typeof user === "object" ? "Send Notification" : "Broadcast Telegram Notification"}</h5>{" "}
            {typeof user === "object" && (
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="td-img round-img m-right-2 pointer">
                  <img src={user?.image?.small ? process.env.REACT_APP_CDN + user.image.small : userIcon} alt="" />
                </div>
                {user?.firstName} {user?.lastName}
                <br />
                {user?.userName}
              </div>
            )}
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">{this.renderTextareaEditor("message", "Message", "", "")}</div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Send"
            loading={this.state.loading}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendUserNotifications: (data, callback) => dispatch(sendUserNotifications(data, callback)),
  sendBroadcastNotifications: (data, callback) => dispatch(sendBroadcastNotifications(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(SendNotificationFlyer));
