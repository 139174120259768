import Joi from "joi-browser";
import _, { isEmpty } from "lodash";
import { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//  Images
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import SelectSearch from "common/form/selectSearch";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import closeIcon from "include/images/close-icon.svg";
import moment from "moment";
import { getTelegram, loadTelegramGames } from "store/telegram";

class GamesPlayedListing extends Form {
  ref = createRef(null);

  state = {
    data: { bgGameName: "", status: null },
    sorting: { sort: "createdAt", order: "desc" },
    errors: {},
    loading: false,
    showFilters: false,
    page: 1,
    appliedFilters: {},
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.telegramGamesLoading &&
      this.props.getTelegram.telegramGamesLoading !== prevProps.getTelegram.telegramGamesLoading
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.telegramGamesLoading &&
      this.props.getTelegram.telegramGamesLoading !== prevProps.getTelegram.telegramGamesLoading
    )
      this.ref.current.continuousStart();
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    const params = { sort: "createdAt", order: "desc" };
    this.props.loadTelegramGames(params, (res) => {
      if (res?.status === 200) {
        this.setState({
          loading: false,
        });
      } else this.setState({ loading: false });
    });
  };

  //  Filters
  searchFilters = () => {
    this.setState({ loadingFilters: true });
    const data = { ...this.state.data };
    const sorting = { ...this.state.sorting };
    const params = {
      bgGameName: data?.bgGameName,
      status: data?.status?.value,

      sort: sorting?.sort,
      order: sorting?.order,
    };
    params.page = this.state.page;
    this.props.loadTelegramGames(params, (userRes) => {
      if (userRes?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
          loading: false,
          loadingMore: false,
          loadingFilters: false,
        });
      } else this.setState({ loading: false, loadingFilters: false });
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          bgGameName: "",
          status: null,
        },
        page: 1,
      },
      () => this.searchFilters(),
    );
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };

  schema = {
    bgGameName: Joi.string().allow(""),
    status: Joi.object().allow(null),
  };
  sort = (e, type) => {
    if (e) e.preventDefault();
    const sorting = { ...this.state.sorting };
    if (type === 1) {
      if (sorting?.sort === "totalScore" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "totalScore";
    } else if (type === 2) {
      if (sorting?.sort === "status" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "status";
    } else if (type === 3) {
      if (sorting?.sort === "createdAt" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "createdAt";
    } else if (type === 4) {
      if (sorting?.sort === "completedAt" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "completedAt";
    }
    this.setState({ sorting, page: 1 }, () => {
      this.searchFilters();
    });
  };
  togglePlayersModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showPlayers: !this.state.showPlayers });
  };
  gameStatus = ["INCOMPLETE", "COMPLETED"];
  render() {
    const { telegramGames, telegramGamesTotalCount } = this.props.getTelegram;

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-games" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Games Played {telegramGamesTotalCount > 0 && `(${telegramGamesTotalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("bgGameName", "BG Game Name")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Status</label>
                          <SelectSearch
                            name="status"
                            options={this.gameStatus.map((status, i) => ({ name: status, value: i + 1 }))}
                            label=""
                            value={this.state.data.status}
                            error={this.state.errors.status}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => {
                          this.setState(
                            {
                              page: 1,
                            },
                            () => this.searchFilters(),
                          );
                        }}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">{key === "bgGameName" ? "Bg Game Name" : key}</span> :{" "}
                              {key === "status" ? this.state.appliedFilters[key].name : this.state.appliedFilters[key]}
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      data: { ...this.state.data, [key]: "" },
                                      page: 1,
                                    },
                                    this.searchFilters,
                                  );
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}{" "}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {telegramGames?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Game</th>

                            <th>
                              Total Score{" "}
                              <a href="#!" onClick={(e) => this.sort(e, 1)}>
                                <img
                                  src={
                                    this.state.sorting?.sort === "totalScore"
                                      ? this.state.sorting?.order === "desc"
                                        ? arrowIcon1
                                        : arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                            <th>
                              Status{" "}
                              <a href="#!" onClick={(e) => this.sort(e, 2)}>
                                <img
                                  src={
                                    this.state.sorting?.sort === "status"
                                      ? this.state.sorting?.order === "desc"
                                        ? arrowIcon1
                                        : arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                            <th>
                              Start Date
                              <a href="#!" onClick={(e) => this.sort(e, 3)}>
                                <img
                                  src={
                                    this.state.sorting?.sort === "createdAt"
                                      ? this.state.sorting?.order === "desc"
                                        ? arrowIcon1
                                        : arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                            <th>
                              Completion Date
                              <a href="#!" onClick={(e) => this.sort(e, 4)}>
                                <img
                                  src={
                                    this.state.sorting?.sort === "completedAt"
                                      ? this.state.sorting?.order === "desc"
                                        ? arrowIcon1
                                        : arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {telegramGames?.map((game, i) => (
                            <tr>
                              <td>{game?.bgGameName}</td>
                              <td>{game?.totalScore?.toLocaleString("en-us") ?? 0}</td>
                              <td className="ust-tag">
                                <span
                                  className={`w-100 text-center d-block camelCase ${
                                    this.gameStatus[game?.status - 1] === "COMPLETED" ? "active" : "suspended"
                                  } `}
                                >
                                  {this.gameStatus[game?.status - 1]}
                                </span>
                              </td>
                              <td>{game?.createdAt && moment(game?.createdAt).format("MM/DD/YYYY hh:mm a")}</td>
                              <td>{game?.completedAt && moment(game?.completedAt).format("MM/DD/YYYY hh:mm a")}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
                {!this.state.loading && !(this.state.page === 1 && telegramGames?.length === 0) && (
                  <div className="page-pagination">
                    <ul className="pagination  justify-content-end">
                      <li className="page-item">
                        <a
                          class={`page-link ${this.state.page === 1 && "disabled"}`}
                          href="#!"
                          aria-label="Previous"
                          onClick={(e) => {
                            e.preventDefault();
                            if (this.state.page > 1) {
                              this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                            }
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      <li className="page-item active">
                        <a
                          className="page-link"
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {this.state.page}
                        </a>
                      </li>
                      {telegramGames?.length >= 50 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            {this.state.page + 1}
                          </a>
                        </li>
                      )}
                      <li className="page-item">
                        <a
                          class={`page-link ${telegramGames?.length < 50 && "disabled"}`}
                          href="#!"
                          aria-label="Next"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadTelegramGames: (pramas, callback) => dispatch(loadTelegramGames(pramas, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GamesPlayedListing));
